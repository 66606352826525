<template>
  <div :class="{ 'border-bottom mb-2': showName }">
    <div v-if="showName" class="ui-type-heading text-primary">
      {{ variant.translations[lang].name }}
    </div>
    <div class="form-group">
      <UiCInputRadioGroup
        :options="options.valid_limited"
        :checked.sync="variant.valid_limited"
        custom
        inline
      />
    </div>
    <CRow v-if="variant.valid_limited === 'limited'">
      <CCol md="4">
        <LabelHelp
          header="Dal giorno"
          content="Queste condizioni sono valide a partire da questa data."
        >
          Dal giorno
        </LabelHelp>

        <UiDatePicker
          :date.sync="variant.valid_from"
          :isValid="variant.isValid('valid_from')"
          :invalidFeedback="variant.getErrorMessage('valid_from')"
        />
      </CCol>
      <CCol md="4">
        <LabelHelp
          header="Al giorno"
          content="Data di scadenza di questo lotto. Verifica di avere un lotto attivo su questo prodotto per non farlo sparire dall'offerta alle famiglie."
        >
          Al giorno
        </LabelHelp>
        <UiDatePicker
          :date.sync="variant.valid_to"
          :isValid="variant.isValid('valid_to')"
          :invalidFeedback="variant.getErrorMessage('valid_to')"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import UiCInputRadioGroup from "../../../../common/form/UiCInputRadioGroup";
import UiDatePicker from "../../../../common/form/UiDatePicker";
import { getValidLimitedOptions } from "../../../../helpers/options";
import LabelHelp from "../../../../common/form/LabelHelp";

export default {
  name: "ProductFormVariantSellTime",
  components: {
    UiCInputRadioGroup,
    UiDatePicker,
    LabelHelp,
  },
  props: {
    variant: { type: Object, required: true },
    lang: { type: String, required: true },
    showName: { type: Boolean, default: false },
  },
  data() {
    return {
      options: {
        valid_limited: getValidLimitedOptions(),
      },
    };
  },
};
</script>
